@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.drop-down {
	padding: 8px;
	border-radius: 4px;
	.MuiList-padding {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		font-size: 14px;
	}
}
.info__drop-down {
	.MuiList-padding {
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
	.drop-down {
		text-align: center;
		background: rgba(0, 0, 0, 0.56);
		color: white;
		font-size: 14px;
	}
}

.drop-down__title {
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	margin-bottom: 8px;
}
.drop-down-shrt-txt {
	font-size: 12px;
	line-height: 16px;
}
.cost-works {
	.drop-down-item {
		font-size: 14px;
		line-height: 16px;
		padding: 0 12px;
		min-height: 32px;
		padding: 4px 4px;
		margin-top: 8px;
		display: flex;
		align-items: center;
		cursor: pointer;
		border-radius: 4px;
		transition: all 0.2s ease-in-out;
		position: relative;
		&:hover {
			background-color: $table-bg;
		}
		&:active {
			color: $main-blue;
			background-color: rgba(27, 170, 240, 0.1);
		}
	}
}

.drop-down__actions {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.drop-down-item {
	height: 32px;
	transition: $transition;
	border-radius: 4px !important;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	&:hover {
		color: $black;
	}
	&:active {
		color: $main-blue;
		background: rgba(27, 170, 240, 0.1) !important;
	}
	.icon-size {
		display: block;
		width: 18px;
		height: 18px;
	}

	.dropdown-icon-bold {
		transform: rotate(-90deg);
	}
	&.open {
		.dropdown-icon-bold {
			transform: rotate(0deg);
		}
	}
	&.MuiListItem-gutters {
		padding-left: 8px !important;
		padding-right: 8px !important;
	}
}

.drop-down_label {
	width: 220px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: block;
	position: relative;
	padding-left: 24px;
	height: 16px;
	line-height: 16px;
	margin-bottom: 12px;
	cursor: pointer;
	&::before {
		content: '';
		position: absolute;
		width: 16px;
		height: 16px;
		border: 1px solid $blue-dark;
		left: 0;
		border-radius: 50%;
		box-sizing: border-box;
	}
}
.drop-down {
	input:checked + .drop-down_label {
		&::before {
			border: 5px solid $blue-dark;
		}
	}
}
.title {
	font-size: 18px;
	line-height: 24px;
}
.action {
	display: flex;
	justify-content: space-between;
}
