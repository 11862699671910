/* SearchSelect.scss */

.searchSelect {
    position: relative;
    display: flex;
  
    .searchInput {
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-width: 200px;
      width: 100%;
      min-height: 34px;
      margin-right: 8px;
    }
    .searchInput::placeholder{
      color: rgb(165, 165, 165);
    }
    .searchInput:focus{
      outline: 1px solid rgb(42, 164, 246);

    }
    
    .optionList {
      list-style: none;
      padding: 0;
      margin: 0;
      position: absolute;
      top: 100%;
      left: 0;
      background-color: #fff;
      border: 1px solid #ccc;
      border-top: none;
      border-radius: 0 0 4px 4px;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      z-index: 9;
      
  
      .optionItem {
        padding: 8px;
        cursor: pointer;
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  
    .searchButton {
      padding: 8px;
      cursor: pointer;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    .searchButton:hover {
      background-color: #cecdcd;
      transition: all 0.3s ease;
    }
    .searchButton:disabled:hover {
      background-color: transparent;
      cursor: default;
    }
  }
.disableOption{
  color: gray;
  pointer-events: none;
}
.errorInput:focus{
  outline: 1px solid red !important;
  color: red;
}